import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i2 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import * as i4 from '@angular/router';
import { RouterModule } from '@angular/router';
const _c0 = ["*"];
function CxNavigationDrawerButtonComponent_mat_icon_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("svgIcon", ctx_r0.iconSvg);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.icon, " ");
  }
}
function CxNavigationDrawerButtonComponent_mat_icon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-icon", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("svgIcon", ctx_r0.iconActiveSvg);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.iconActive, " ");
  }
}
function CxNavigationDrawerButtonComponent_span_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.badge);
  }
}
class CxNavigationDrawerComponent {
  static {
    this.ɵfac = function CxNavigationDrawerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxNavigationDrawerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxNavigationDrawerComponent,
      selectors: [["cx-navigation-drawer"]],
      standalone: false,
      ngContentSelectors: _c0,
      decls: 2,
      vars: 0,
      consts: [[1, "cx-navigation-drawer"]],
      template: function CxNavigationDrawerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
      },
      styles: [".cx-navigation-drawer[_ngcontent-%COMP%]{width:256px;box-sizing:border-box;position:fixed;z-index:500;display:block;top:76px;padding:12px 8px 16px;max-height:100%;bottom:0;overflow-y:auto;box-shadow:0 4px 8px #20202029;background-color:#fff}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNavigationDrawerComponent, [{
    type: Component,
    args: [{
      selector: 'cx-navigation-drawer',
      standalone: false,
      template: "<div class=\"cx-navigation-drawer\">\n  <ng-content></ng-content>\n</div>\n",
      styles: [".cx-navigation-drawer{width:256px;box-sizing:border-box;position:fixed;z-index:500;display:block;top:76px;padding:12px 8px 16px;max-height:100%;bottom:0;overflow-y:auto;box-shadow:0 4px 8px #20202029;background-color:#fff}\n"]
    }]
  }], null, null);
})();

/* eslint-disable no-underscore-dangle */
class CxNavigationDrawerButtonComponent {
  constructor() {
    this.name = 'Button';
    this.routerLink = null;
    this.queryParams = null;
    this.queryParamsHandling = null;
    this.state = null;
    this.fragment = null;
    this.preserveFragment = null;
    this.routerLinkActiveOptions = {
      exact: true
    };
    this._badge = '';
    this.POSITIVE_ABOVE_ZERO_NUMBER_EXP = /^[1-9][0-9]*$/;
  }
  get badge() {
    return this._badge;
  }
  set badge(value) {
    if (!this.POSITIVE_ABOVE_ZERO_NUMBER_EXP.test(value.toString())) {
      this._badge = '';
      return;
    }
    this._badge = value > 999 ? '999+' : value.toString();
  }
  static {
    this.ɵfac = function CxNavigationDrawerButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxNavigationDrawerButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxNavigationDrawerButtonComponent,
      selectors: [["cx-navigation-drawer-button"]],
      inputs: {
        name: "name",
        icon: "icon",
        iconSvg: "iconSvg",
        iconActive: "iconActive",
        iconActiveSvg: "iconActiveSvg",
        routerLink: "routerLink",
        queryParams: "queryParams",
        queryParamsHandling: "queryParamsHandling",
        state: "state",
        fragment: "fragment",
        preserveFragment: "preserveFragment",
        routerLinkActiveOptions: "routerLinkActiveOptions",
        badge: "badge"
      },
      standalone: false,
      decls: 6,
      vars: 11,
      consts: [["mat-stroked-button", "", "routerLinkActive", "cx-navigation-drawer-button-active", 1, "cx-navigation-drawer-button", "cx-button-with-icon", "w-100", 3, "routerLink", "queryParams", "queryParamsHandling", "state", "fragment", "preserveFragment", "routerLinkActiveOptions"], ["class", "cx-icon-in-button", 3, "svgIcon", 4, "ngIf"], ["class", "cx-icon-in-button cx-navigation-drawer-active-icon", 3, "svgIcon", 4, "ngIf"], [1, "cx-navigation-drawer-button-text"], ["class", "cx-badge-notification-lg", 4, "ngIf"], [1, "cx-icon-in-button", 3, "svgIcon"], [1, "cx-icon-in-button", "cx-navigation-drawer-active-icon", 3, "svgIcon"], [1, "cx-badge-notification-lg"]],
      template: function CxNavigationDrawerButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵtemplate(1, CxNavigationDrawerButtonComponent_mat_icon_1_Template, 2, 2, "mat-icon", 1)(2, CxNavigationDrawerButtonComponent_mat_icon_2_Template, 2, 2, "mat-icon", 2);
          i0.ɵɵelementStart(3, "span", 3);
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, CxNavigationDrawerButtonComponent_span_5_Template, 2, 1, "span", 4);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("routerLink", ctx.routerLink)("queryParams", ctx.queryParams)("queryParamsHandling", ctx.queryParamsHandling)("state", ctx.state)("fragment", ctx.fragment)("preserveFragment", ctx.preserveFragment)("routerLinkActiveOptions", ctx.routerLinkActiveOptions);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.icon || ctx.iconSvg);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.iconActive || ctx.iconActiveSvg);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.name);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.badge.length > 0);
        }
      },
      dependencies: [i1.NgIf, i2.MatIcon, i3.MatButton, i4.RouterLink, i4.RouterLinkActive],
      styles: [".cx-navigation-drawer-button[_ngcontent-%COMP%]{text-transform:none;text-align:left;font-weight:400;border:none;height:fit-content;font-size:14px}.cx-navigation-drawer-button-text[_ngcontent-%COMP%]{white-space:pre-wrap;margin-right:auto}.cx-navigation-drawer-button-active[_ngcontent-%COMP%]{color:#00a97a!important;background-color:#00a97a14}.cx-navigation-drawer-button[_ngcontent-%COMP%]   .cx-navigation-drawer-active-icon[_ngcontent-%COMP%], .cx-navigation-drawer-button[_ngcontent-%COMP%]   .cx-navigation-drawer-button-active[_ngcontent-%COMP%]{display:none}@supports selector(:has(*)){.cx-navigation-drawer-button.cx-navigation-drawer-button-active[_ngcontent-%COMP%]   .mat-icon.mat-icon[_ngcontent-%COMP%]:has(+.cx-navigation-drawer-active-icon){display:none}}@supports not selector(:has(*)){.cx-navigation-drawer-button.cx-navigation-drawer-button-active[_ngcontent-%COMP%]   .mat-icon.mat-icon[_ngcontent-%COMP%]:is(.cx-navigation-drawer-active-icon){display:none}}.cx-navigation-drawer-button.cx-navigation-drawer-button-active[_ngcontent-%COMP%]   .cx-navigation-drawer-active-icon[_ngcontent-%COMP%]{display:inline-block;margin-left:-2px!important;margin-top:-2px!important}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNavigationDrawerButtonComponent, [{
    type: Component,
    args: [{
      selector: 'cx-navigation-drawer-button',
      standalone: false,
      template: "<button\n  mat-stroked-button\n  routerLinkActive=\"cx-navigation-drawer-button-active\"\n  class=\"cx-navigation-drawer-button cx-button-with-icon w-100\"\n  [routerLink]=\"routerLink\"\n  [queryParams]=\"queryParams\"\n  [queryParamsHandling]=\"queryParamsHandling\"\n  [state]=\"state\"\n  [fragment]=\"fragment\"\n  [preserveFragment]=\"preserveFragment\"\n  [routerLinkActiveOptions]=\"routerLinkActiveOptions\"\n>\n  <mat-icon *ngIf=\"icon || iconSvg\" class=\"cx-icon-in-button\" [svgIcon]=\"iconSvg\">\n    {{ icon }}\n  </mat-icon>\n  <mat-icon *ngIf=\"iconActive || iconActiveSvg\" class=\"cx-icon-in-button cx-navigation-drawer-active-icon\" [svgIcon]=\"iconActiveSvg\">\n    {{ iconActive }}\n  </mat-icon>\n  <span class=\"cx-navigation-drawer-button-text\">{{ name }}</span>\n  <span *ngIf=\"badge.length > 0\" class=\"cx-badge-notification-lg\">{{ badge }}</span>\n</button>\n",
      styles: [".cx-navigation-drawer-button{text-transform:none;text-align:left;font-weight:400;border:none;height:fit-content;font-size:14px}.cx-navigation-drawer-button-text{white-space:pre-wrap;margin-right:auto}.cx-navigation-drawer-button-active{color:#00a97a!important;background-color:#00a97a14}.cx-navigation-drawer-button .cx-navigation-drawer-active-icon,.cx-navigation-drawer-button .cx-navigation-drawer-button-active{display:none}@supports selector(:has(*)){.cx-navigation-drawer-button.cx-navigation-drawer-button-active .mat-icon.mat-icon:has(+.cx-navigation-drawer-active-icon){display:none}}@supports not selector(:has(*)){.cx-navigation-drawer-button.cx-navigation-drawer-button-active .mat-icon.mat-icon:is(.cx-navigation-drawer-active-icon){display:none}}.cx-navigation-drawer-button.cx-navigation-drawer-button-active .cx-navigation-drawer-active-icon{display:inline-block;margin-left:-2px!important;margin-top:-2px!important}\n"]
    }]
  }], null, {
    name: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    iconSvg: [{
      type: Input
    }],
    iconActive: [{
      type: Input
    }],
    iconActiveSvg: [{
      type: Input
    }],
    routerLink: [{
      type: Input
    }],
    queryParams: [{
      type: Input
    }],
    queryParamsHandling: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    fragment: [{
      type: Input
    }],
    preserveFragment: [{
      type: Input
    }],
    routerLinkActiveOptions: [{
      type: Input
    }],
    badge: [{
      type: Input
    }]
  });
})();
class CxNavigationDrawerContainerComponent {
  static {
    this.ɵfac = function CxNavigationDrawerContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxNavigationDrawerContainerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxNavigationDrawerContainerComponent,
      selectors: [["cx-navigation-drawer-container"]],
      standalone: false,
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CxNavigationDrawerContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      styles: ["[_nghost-%COMP%]{margin-left:256px;display:block}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNavigationDrawerContainerComponent, [{
    type: Component,
    args: [{
      selector: 'cx-navigation-drawer-container',
      standalone: false,
      template: "<ng-content></ng-content>\n",
      styles: [":host{margin-left:256px;display:block}\n"]
    }]
  }], null, null);
})();
class CxNavigationDrawerHeadlineComponent {
  static {
    this.ɵfac = function CxNavigationDrawerHeadlineComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxNavigationDrawerHeadlineComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CxNavigationDrawerHeadlineComponent,
      selectors: [["cx-navigation-drawer-headline"]],
      standalone: false,
      ngContentSelectors: _c0,
      decls: 2,
      vars: 0,
      consts: [[1, "cx-navigation-drawer-headline"]],
      template: function CxNavigationDrawerHeadlineComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
      },
      styles: [".cx-navigation-drawer-headline[_ngcontent-%COMP%]{display:inline-block;padding:10px 16px;margin-top:4px;margin-bottom:4px;font-size:12px;font-weight:400;color:#202020;text-transform:uppercase;white-space:pre-wrap}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNavigationDrawerHeadlineComponent, [{
    type: Component,
    args: [{
      selector: 'cx-navigation-drawer-headline',
      standalone: false,
      template: "<div class=\"cx-navigation-drawer-headline\">\n  <ng-content></ng-content>\n</div>\n",
      styles: [".cx-navigation-drawer-headline{display:inline-block;padding:10px 16px;margin-top:4px;margin-bottom:4px;font-size:12px;font-weight:400;color:#202020;text-transform:uppercase;white-space:pre-wrap}\n"]
    }]
  }], null, null);
})();
const components = [CxNavigationDrawerComponent, CxNavigationDrawerButtonComponent, CxNavigationDrawerContainerComponent, CxNavigationDrawerHeadlineComponent];
class CxNavigationDrawerModule {
  static {
    this.ɵfac = function CxNavigationDrawerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CxNavigationDrawerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CxNavigationDrawerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatListModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CxNavigationDrawerModule, [{
    type: NgModule,
    args: [{
      declarations: [...components],
      imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule, MatListModule],
      exports: [...components]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CxNavigationDrawerButtonComponent, CxNavigationDrawerComponent, CxNavigationDrawerContainerComponent, CxNavigationDrawerHeadlineComponent, CxNavigationDrawerModule };
